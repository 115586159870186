import crudGetters from "@/store/templates/crud/getters";

export default {
  ...crudGetters,
  employee: state => state.model,
  id: state => state.model.id,
  firstName: state => state.model.firstName,
  lastName: state => state.model.lastName,
  user: state => state.user[state.model.user],
  email: state => state.model.user.email,
  roles: state => state.model.user.roles,
  permissions: state => state.model.user.permissions,
  color: state => state.model.color,
  fullName: state => {
    return `${state.model.firstName || ""} ${state.model.lastName || ""}`;
  },
  picture: state => state.model.picture
};
