import Vue from "vue";
import { cloneDeep } from "lodash";
import crudMutations from "@/store/templates/crud/mutations";
import { SET_MODEL } from "@/store/templates/crud/mutation-types";
import { setEntities } from "@/store/templates/entities";

export default {
  ...crudMutations,
  [SET_MODEL](state, { entities, id }) {
    const initialModel = cloneDeep(entities.entity[id]);
    // clone enough for localizations, as they only contain string properties. Object.assign({}, target) would work
    // too, but if target is null or undefined this would give an empty object rather than null or undefined.
    initialModel.user = cloneDeep(entities.user[initialModel.user]);
    Vue.set(state, "initialModel", initialModel);
    Vue.set(state, "model", cloneDeep(initialModel));

    setEntities(state, entities);
  },
  setFirstName(state, firstName) {
    state.model.firstName = firstName;
  },
  setLastName(state, lastName) {
    state.model.lastName = lastName;
  },
  setEmail(state, email) {
    state.model.user.email = email;
  },
  setRoles(state, roles) {
    state.model.user.roles = roles;
  },
  setPermissions(state, permissions) {
    state.model.user.permissions = permissions;
  },
  setColor(state, color) {
    state.model.color = color;
  },
  setPicture(state, picture) {
    state.model.picture = picture;
  }
};
