import initialCrudState from "@/store/templates/crud/state";

const initialModel = () => ({
  id: null,
  firstName: null,
  lastName: null,
  user: {
    email: null
  },
  color: null,
  picture: null,
  pictureUpload: null
});

export default () => ({
  ...initialCrudState(initialModel),
  user: {},
  role: {},
  permission: {}
});
